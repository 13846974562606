import useTranslation from 'next-translate/useTranslation'

const HomeSlogan = () => {
  const { t } = useTranslation('home')
  return (
    <div className='bg-action pt-16 pb-8'>
      <div className='container'>
        <div className='flex flex-col text-center md:text-left md:flex-row items-center gap-4 md:gap-8 text-white'>
          <h1 className='font-black md:text-5xl lg:text-[50px]'>
            {t('slogan_main')}
          </h1>
          <h2 className='font-medium lg:text-[32px] text-lg'>
            {t('slogan_sub')}
          </h2>
        </div>
      </div>
    </div>
  )
}
export default HomeSlogan
