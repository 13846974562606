
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import type { GetServerSideProps } from 'next'
import { useReducer } from 'react'
import { addApolloState, initializeApollo } from '../libs/api/apollo'
import {
  MetadataQuery,
  MetadataDocument,
  MetadataQueryVariables,
  LocaleEnum,
  PageEnum,
  Metadata,
  SearchJobsQueryVariables,
  SearchJobsQuery,
  SearchJobsDocument,
  JobsLocationsQuery,
  JobsLocationsQueryVariables,
  JobsLocationsDocument,
  Job,
} from '../generated/apolloComponents'
import CustomHead from '@/components/molecules/CustomHead'
import {
  JobListContext,
  JobListState,
  jobListReducer,
} from '@/context/jobListContext'
import { ScrollRestorer } from '@/components/atoms/ScrollRestorer'
import JobListHome from '@/components/templates/JobsListHome'

interface Props {
  metaData: Metadata
  jobs: Job[]
  jobsLocations: string[]
  totalCount: number
}

const Home = ({ jobs, jobsLocations, metaData, totalCount }: Props) => {
  const slugState = {} as JobListState
  const [state, dispatch] = useReducer(jobListReducer, slugState)

  return (
    <>
      <CustomHead metaData={metaData} />
      <JobListContext.Provider value={{ state, dispatch }}>
        <ScrollRestorer />
        <JobListHome
          jobs={jobs}
          jobsLocations={jobsLocations}
          totalCount={totalCount}
          slugState={slugState}
        />
      </JobListContext.Provider>
    </>
  )
}

 const _getServerSideProps: GetServerSideProps = async (context: any) => {
  const apolloClient = initializeApollo({ ctx: context })

  const variables: SearchJobsQueryVariables = {
    page: 1,
    perPage: 50,
    filters: {},
    language: LocaleEnum[context.locale as 'pl' | 'en'],
  }
  const [jobsData, jobsLocationsData, metaData] = await Promise.all([
    apolloClient.query<SearchJobsQuery, SearchJobsQueryVariables>({
      query: SearchJobsDocument,
      variables: variables,
    }),
    apolloClient.query<JobsLocationsQuery, JobsLocationsQueryVariables>({
      query: JobsLocationsDocument,
      variables: {
        language: LocaleEnum[context.locale as 'pl' | 'en'],
      },
    }),
    apolloClient.query<MetadataQuery, MetadataQueryVariables>({
      query: MetadataDocument,
      variables: {
        pageType: PageEnum.Home,
        language: LocaleEnum[context.locale as 'pl' | 'en'],
      },
    }),
  ])

  return addApolloState(apolloClient, {
    props: {
      metaData: metaData.data.metadata,
      totalCount: jobsData.data.searchJobs.totalCount,
      jobs: jobsData.data.searchJobs.nodes,
      jobsLocations: jobsLocationsData.data.jobsLocations,
    },
  })
}

export default Home


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  